@import "../../themes/variables.scss";

.list-of-products {
  background: #e2e2e2;
  padding: 40px 0 60px;
  h5 {
    color: $primary;
    position: relative;
    overflow: hidden;
    &::after {
      content: "";
      border-bottom: 1px solid $primary;
      position: absolute;
      width: 100%;
      bottom: 0;
      margin-left: 10px;
    }
  }
  .btn-back {
    margin-bottom: 40px;
  }
  .cont-card {
    height: 100%;
    padding: 10px 0;
    width: 100%;
    .card {
      height: 100%;
      max-height: none;
      margin: 0 auto;
      min-height: 250px;
      .card-img {
        margin: auto 0;
        height: 100%;
        object-fit: contain;
      }
    }
  }
}

.btn-icon-left {
  margin-right: 10px;
}
