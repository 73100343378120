@import './themes/variables.scss';
@import '~bootstrap/scss/bootstrap.scss';
@font-face {
  font-family: "BebasNeue";
  src: url("../src/fonts/BebasNeue.otf") format("opentype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: 'BebasNeue', 'Segoe UI', 'Roboto', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
  word-spacing: 2.3px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
